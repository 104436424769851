#client-logo-img {
    height: 100%;
    width: 100%;
}

#clients {
    width: 100%;
    height: auto;
}

.client-box {
    width: 225px;
    height: 151px;
    background-color: #242424;
    display: block;
    margin: 0.5rem auto;
}

.client-box:hover {
    background-color: #FFC10E;
    color: #000;
}

.client-item {
    padding-top: 2.5rem;
}

.client-item p {
    font-family: "avenir-next-lt-pro-Regular";
    text-transform: none;
}

.no-gutters {
    padding: 0 5rem;
}

@media screen and (max-width: 500px) {
    .client-gutter {
        margin-left: -2rem;
    }
}

@media (min-width: 500px) and (max-width: 768px) {}