@font-face {
    font-family: "avenir-next-lt-pro-bold";
    src: url("../../fonts/AvenirNextLTPro-Bold.otf") format("woff"),
        url("../../fonts/AvenirNextLTPro-Bold.otf") format("opentype"),
        url("../../fonts/AvenirNextLTPro-Bold.otf") format("truetype");
}

@font-face {
    font-family: "avenir-next-lt-pro-Regular";
    src: url("../../fonts/AvenirNextLTPro-Regular.otf") format("woff"),
        url("../../fonts/AvenirNextLTPro-Regular.otf") format("opentype"),
        url("../../fonts/AvenirNextLTPro-Regular.otf") format("truetype");
}

.package-title {
    background-color: #ffd762;
    /* background-color: rgba(255,215,98, 1); */
    padding: 0.2rem;
    text-align: center;
    /* font-family: 'avenir-next-lt-pro-bold'; */
    font-weight: bold !important;
}

.start-with-looper {
    border: none !important;
    background-color: rgb(255, 193, 14) !important;
    margin-left: 7rem;
}

.looper-icon {
    width: 14%;
    margin-left: 0.5rem;
}

#pack-item {
    margin-left: -40px;
}
#package-text {
    font-family: "avenir-next-lt-pro-bold";
    text-transform: none;
    font-size: 6rem !important;
    line-height: 0.9;
    margin-left: 1rem;
}
#johann {
    font-family: "avenir-next-lt-pro-bold";
    margin-top: 3rem;
    margin-left: 29rem;
    font-size: 24px;
}
ul {
    list-style: square inside
        url("data:image/gif;base64,R0lGODlhBQAKAIABAAAAAP///yH5BAEAAAEALAAAAAAFAAoAAAIIjI+ZwKwPUQEAOw==");
    font-family: "avenir-next-lt-pro-Regular";
}
ul li {
    padding: 0.3rem 0;
}

@media screen and (max-width: 500px) {
    #package-text {
        font-family: "avenir-next-lt-pro-bold";
        text-transform: none;
        font-size: 2.5rem !important;
        letter-spacing: 0.4rem;
    }
    .card {
        height: auto !important;
        margin-bottom: 1rem;
    }
    .card-footer {
        margin-left: 0rem !important;
    }
}

@media (min-width: 500px) and (max-width: 768px) {
    #package-text {
        font-family: "avenir-next-lt-pro-bold";
        text-transform: none;
        font-size: 2.5rem !important;
        letter-spacing: 0.4rem;
    }

    .card {
        height: auto !important;
        margin-bottom: 1rem;
    }

    .card-footer {
        margin-left: 0rem !important;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    #package-text {
        font-family: "avenir-next-lt-pro-bold";
        text-transform: none;
        font-size: 2.5rem !important;
        letter-spacing: 0.4rem;
    }
    .card {
        height: auto !important;
        margin-bottom: 1rem;
    }

    .card-footer {
        margin-left: 0rem !important;
    }
}
@media (min-width: 992px) and (max-width: 1200px) {
    .card {
        height: auto !important;
        margin-bottom: 1rem;
    }

    .card-footer {
        margin-left: 0rem !important;
    }
}
