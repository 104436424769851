@font-face {
    font-family: "avenir-next-lt-pro-bold";
    src: url("../../fonts/AvenirNextLTPro-Bold.otf") format("woff"),
        url("../../fonts/AvenirNextLTPro-Bold.otf") format("opentype"),
        url("../../fonts/AvenirNextLTPro-Bold.otf") format("truetype");
}

@font-face {
    font-family: "avenir-next-lt-pro-Regular";
    src: url("../../fonts/AvenirNextLTPro-Regular.otf") format("woff"),
        url("../../fonts/AvenirNextLTPro-Regular.otf") format("opentype"),
        url("../../fonts/AvenirNextLTPro-Regular.otf") format("truetype");
}

.located-p {
    /* font-size: 14px; */
    font-family: "avenir-next-lt-pro-Regular";
}

#about-text {
    font-family: "avenir-next-lt-pro-bold";
    text-transform: none;
    font-size: 77px;
    line-height: 0.9;
    /* line-height: 76px; */
    margin-left: 1rem;
}

#jim {
    font-family: "avenir-next-lt-pro-bold";
    margin-left: 73%;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.about-p {
    font-family: "avenir-next-lt-pro-Regular";
    width: 100%;
    text-align: justify;
    font-size: 16px;
    line-height: 28px;
    /* margin-left: 1rem; */
}

.title-owner {
    font-family: "avenir-next-lt-pro-bold";
    /* font-size: 20px; */
}


.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #FFC10E !important;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
}

.caro {
    height: auto;
}

.carousel-inner {
    margin: 0 auto;
    text-align: center;
    display: block;
    padding-top: 0.5rem;
}
a.carousel-control-prev {
    display: none;
}
a.carousel-control-next {
    display: none;
}

.active.carousel-item {
    border: none;
}

@media screen and (max-width: 500px) {
    #about-text {
        font-size: 2rem;
        line-height: normal;
    }

    .about-p {
        width: 100%;
        margin-left: 0rem;

    }
}

@media(min-width: 500px) and (max-width: 768px) {
    #about-text {
        font-size: 3rem;
        
    }

}

@media (min-width: 768px) and (max-width: 992px) {

}