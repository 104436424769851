@font-face {
    font-family: "avenir-next-lt-pro-bold";
    src: url("../../fonts/AvenirNextLTPro-Bold.otf") format("woff"),
        url("../../fonts/AvenirNextLTPro-Bold.otf") format("opentype"),
        url("../../fonts/AvenirNextLTPro-Bold.otf") format("truetype");
}

@font-face {
    font-family: "avenir-next-lt-pro-Regular";
    src: url("../../fonts/AvenirNextLTPro-Regular.otf") format("woff"),
        url("../../fonts/AvenirNextLTPro-Regular.otf") format("opentype"),
        url("../../fonts/AvenirNextLTPro-Regular.otf") format("truetype");
}

.order-nav:hover {
    color: black !important;
}

.icon {
    margin-top: 3px;
}

.phone-nav:hover {
    color: black !important;
}
.phone-nav {
    display: flex;
    padding: 10px;
}

.phone-text {
    margin-top: -10px !important;
    margin-left: 20px !important;
    font-family: "avenir-next-lt-pro-Regular" !important;
}

.sticky-container {
    /* background-color: #FFC10E; */
    padding: 0px;
    margin: 0px;
    position: fixed;
    right: -119px;
    top: 130px;
    width: 200px;
}

.sticky li {
    list-style-type: none;
    /* background-color: yellow !important; */
    background-color: #333;
    /* color: #efefef; */
    height: 43px;
    padding: 0px;
    margin: 0px 0px 1px 0px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%);
}

.first-li {
    margin-left: 0 !important;
}

.sticky li:hover {
    margin-left: -115px;
    background-color: #ffc10e !important;
    color: black !important;
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
}

.sticky li img {
    float: left;
    margin: 5px 5px;
    margin-right: 10px;
}

.sticky li p {
    padding: 0px;
    margin: 0px;
    text-transform: uppercase;
    line-height: 43px;
}
