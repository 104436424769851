.just-img:hover {
    transition: ease 0.3s all;
    /* transform: scale(1.1,1.1); */
    transform: scale3d(1.3, 1.3, 1.3);
    display: block;
}

.overlay {
    transition: ease 0.3s all;
    transform: scale(1.1,1.1);
    /* position: absolute; */
    /* bottom: 0;
    left: 0;
    right: 0; */
    /* background-color: #008cba; */
    /* overflow: hidden; */
    /* 
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease; */
    /* display: block; */
}

.client-box:hover .overlay {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

/* .image-enlarge:hover {
    transform: scale(1.5);
} */

.btn-group {
    margin-left: 5rem;
}

#search {
    padding: 0.2rem 3rem 0.2rem 1rem;
    border-radius: 1rem;
}

.dropdown-menu.show {
    margin-top: 118px;
    border-radius: 1rem;
    
    /* position: absolute; */
    /* transform: translate3d(0px, 34px, 0px); */
    /* top: -0px !important; */
    /* left: 0px; */
    /* will-change: transform; */
    /* padding-right: 2rem; */
    cursor: pointer;
    /* margin-top: 142px; */
    margin-top: 8.875rem;
}

.works-box {
    width: 217px;
    height: 217px;
    background-color: #333333;
    display: block;
    margin: 0.5rem auto;
    z-index: -1;
}

.works-box:hover {
    background-color: #ffc10e;
    color: #000;
}

.works-item {
    padding-top: 2.5rem;
}

.works-item p {
    font-family: "avenir-next-lt-pro-Regular";
    text-transform: none;
}
@media screen and (max-width: 500px) {
    .client-box {
        margin-left: -2.5rem;
    }
}

@media (min-width: 500px) and (max-width: 768px) {
}
