@font-face {
    font-family: "avenir-next-lt-pro-bold";
    src: url("../../fonts/AvenirNextLTPro-Bold.otf") format("woff"),
        url("../../fonts/AvenirNextLTPro-Bold.otf") format("opentype"),
        url("../../fonts/AvenirNextLTPro-Bold.otf") format("truetype");
}

@font-face {
    font-family: "avenir-next-lt-pro-Regular";
    src: url("../../fonts/AvenirNextLTPro-Regular.otf") format("woff"),
        url("../../fonts/AvenirNextLTPro-Regular.otf") format("opentype"),
        url("../../fonts/AvenirNextLTPro-Regular.otf") format("truetype");
}

.hero-img {
    width: 3.5%;
    margin-left: 1rem;
    cursor: pointer;
}

#home {
    width: 100%;
    height: auto;
}

#hero-text {
    font-family: "avenir-next-lt-pro-bold";
    text-transform: none;
    font-size: 7rem !important;
    line-height: 0.9 !important;
    /* margin-top: 1rem; */
    margin-left: 1rem;
}

#hero-p {
    font-family: "avenir-next-lt-pro-Regular";
    text-transform: none;
    width: 83%;
    text-align: none;
    /* padding-top: 1rem; */
    margin-left: 1rem;
    font-size: 1.25rem;
    line-height: 38.4px;
}

#hero-h6 {
    font-family: "avenir-next-lt-pro-Regular" !important;
    text-transform: none;
    color: #ffc10e;
    margin-left: 1rem;
    font-size: 1.625rem;
    margin-top: 80px;
    margin-bottom: 54px !important;
    /* font-weight: 100; */
}

@media screen and (max-width: 500px) {
    #hero-text {
        font-family: "avenir-next-lt-pro-bold";
        text-transform: none;
        font-size: 4rem !important;
        letter-spacing: normal;
        line-height: normal;
    }

    #hero-p {
        width: 95% !important;
    }

    .hero-img {
        width: 4.5% !important;
    }
}
@media (min-width: 500px) and (max-width: 768px) {
    #hero-text {
        font-family: "avenir-next-lt-pro-bold";
        text-transform: none;
        font-size: 4rem !important;
        letter-spacing: normal;
        line-height: normal;
    }

    #hero-p {
        width: 100% !important;
    }

    .hero-img {
        width: 4.5% !important;
    }
}
/* @media (min-width: 768px) and (max-width: 992px) {
    
} */
