.admin-title-line {
    width: 100%;
    height: 1px;
    text-align: left;
    margin-left: 0;
    margin-top: 18px;
    /* background: #ffc10e; */
    background: rgba(255, 193, 14, 0.5);
}

.card-logo {
    height: 109px;
    width: 100%;
    padding: 19px 50px;
}

.eco-text {
    color: #ffc10e;
    text-align: center;
    font-size: 28px !important;
}
