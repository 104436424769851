.carousel-control-next {
    color: red;
    /* background-color: red; */
}
.c-img-style{
    width: 9%;
}

#client-review {
    width: 100%;
    height: auto;
}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    /* background-color: #1BCE7C !important; */
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
    margin-bottom: -20px;
}

.caro {
    height: 20rem;
}

.carousel-inner {
    margin: 0 auto;
    text-align: center;
    display: block;
    padding-top: 5rem;
}

@media screen and (max-width: 500px) {
    .caro {
        height: 24rem;
    }

}