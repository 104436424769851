@font-face {
    font-family: "avenir-next-lt-pro-bold";
    src: url("../../fonts/AvenirNextLTPro-Bold.otf") format("woff"),
        url("../../fonts/AvenirNextLTPro-Bold.otf") format("opentype"),
        url("../../fonts/AvenirNextLTPro-Bold.otf") format("truetype");
}

@font-face {
    font-family: "avenir-next-lt-pro-Regular";
    src: url("../../fonts/AvenirNextLTPro-Regular.otf") format("woff"),
        url("../../fonts/AvenirNextLTPro-Regular.otf") format("opentype"),
        url("../../fonts/AvenirNextLTPro-Regular.otf") format("truetype");
}

#service {
    width: 100%;
    height: auto;
    margin-top: 20px;
}

#service-text {
    font-family: "avenir-next-lt-pro-bold";
    text-transform: none;
    font-size: 5.5rem !important;
    /* letter-spacing: 0.4rem; */
    line-height: 1 !important;
    margin-left: 1rem;
}

/* #hero-p {
    font-family: "avenir-next-lt-pro-Regular";
    text-transform: none;
    width: 75%;
    text-align: justify;
    padding-top: 1rem;
} */

#hero-h6 {
    font-family: "avenir-next-lt-pro-bold";
    text-transform: none;
    color: #FFC10E;
    margin-bottom: 30px !important;
    margin-top: 30px;
}

#seth {
    font-family: "avenir-next-lt-pro-bold";
    margin-left: 69%;
    margin-top: 35px;
    font-size: 24px;
}

.advertising-box {
    width: 313px;
    height: 178px;
    background-color: #333333;
    display: block;
    margin: 0.5rem -6px;

    /* padding-left: 0 !important; */
    /* margin-left: 0 !important; */
}

.advertising-box:hover {
    background-color: #FFC10E;
    color: #000;
    /* background-image: url('./../../../src/icons/Icon_2D\ Animation_Black.svg'); */
}

.advertising-box img:hover {
    /* background-color: black; */
}

.advertising-item {
    padding-top: 2.5rem;
}

.advertising-item p {
    font-family: "avenir-next-lt-pro-Regular";
    text-transform: none;
}


@media screen and (max-width: 500px) {
    #service-text {
        font-size: 1.5rem !important;
        letter-spacing: normal;
        line-height: normal !important;
    }

    #seth {
        margin-left: 26%;
    }

    .advertising-box {
        width: 235px;
    }
    button.navbar-toggler.collapsed {
        margin-left: 14rem ;
    }

    #hero-h6 {
        margin-left: 1rem;
        font-size: 22px;
    }

    .no-gutters.row {
        margin-left: 23px !important;
    }

    div#social-sidebar {
        right: -85px;
    }

}

@media(min-width: 500px) and (max-width: 768px) {
    #service-text {
        font-size: 3.4rem !important;
        letter-spacing: normal;
        line-height: normal !important;
    }

    #seth {
        margin-left: 26%;
    }
    button.navbar-toggler.collapsed {
        margin-left: 28rem;
    }

    #hero-h6 {
        margin-left: 1rem;
        font-size: 22px;
    }

    .no-gutters.row {
        margin-left: 87px !important;
    }

    div#social-sidebar {
        right: -68px;
    }

    

}

/* @media (min-width: 768px) and (max-width: 992px) {
    
} */