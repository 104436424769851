@font-face {
    font-family: "avenir-next-lt-pro-bold";
    src: url("../../fonts/AvenirNextLTPro-Bold.otf") format("woff"),
        url("../../fonts/AvenirNextLTPro-Bold.otf") format("opentype"),
        url("../../fonts/AvenirNextLTPro-Bold.otf") format("truetype");
}

@font-face {
    font-family: "avenir-next-lt-pro-Regular";
    src: url("../../fonts/AvenirNextLTPro-Regular.otf") format("woff"),
        url("../../fonts/AvenirNextLTPro-Regular.otf") format("opentype"),
        url("../../fonts/AvenirNextLTPro-Regular.otf") format("truetype");
}
#contact {
    width: 100%;
}

#contact-text {
    font-family: "avenir-next-lt-pro-bold";
    text-transform: none;
    font-size: 93.32px;
    line-height: 0.9;
    margin-left: 1rem;
    /* line-height: 76px; */
    /* margin-left: 1rem; */
}

#john {
    font-family: "avenir-next-lt-pro-bold";
    margin-left: 60%;
    margin-top: 3rem;
    margin-bottom: 1rem;
}
/* .main-t {
    margin-left: 6rem;
} */
.contactForm {
    width: 100%;
}
.contactForm input {
    display: block;
    padding: 10px 20px;
    border: none;
    background-color: #D9D9D9;
    width: 100%;
    font-family: "avenir-next-lt-pro-Regular";
}

.contactForm textarea {
    display: block;
    padding: 10px 20px;
    background-color: #D9D9D9;
    width: 100%;
    font-family: "avenir-next-lt-pro-Regular";
}

::placeholder {
    color: #000;
}

#contactBtn {
    width: 34%;
    float: right;
    background-color: #C4161C;
    color: #fff !important;
}
#left-contact .left-h2 {
    font-size: 50px;
    font-family: "avenir-next-lt-pro-bold";
}
#left-contact {
    width: 44%;
    float: right;
    text-align: end;
    font-family: "avenir-next-lt-pro-Regular";
}

@media screen and (max-width: 500px) {
    #contact-text {
        font-size: 43.32px;
    }
    #left-contact {
        width: 100%;
        text-align: center;
    }
    .main-t {
        margin-left: 0rem;
    }

    .contactForm {
        width: 100%;
    }
    .contactForm input {
        width: 100%;
    }
    .contactForm textarea {
        width: 100%;
    }
    #contactBtn {
        float: right;
        width: 44%;
    }
}

@media(min-width: 500px) and (max-width: 768px) {
    #contact-text {
        font-size: 65.32px;
    }
    #left-contact {
        width: 100%;
        text-align: center;
    }

    .main-t {
        margin-left: 0rem;
    }

    .contactForm {
        width: 100%;
    }

    .contactForm input {
        width: 100%;
    }

    .contactForm textarea {
        width: 100%;
    }

    #contactBtn {
        float: right;
        width: 44%;
    }
}

