.package-detail {
    color: #FFC113;
    width: 15rem;
}

.package-detail:hover {
    /* text-decoration: none !important; */
    /* color: #FFC113; */
    /* text-decoration: #C4161C; */
}

.form-divider {
    width: 94.5%;
    height: 1px;
    text-align: left;
    margin-left: 0;
    margin-top: 18px;
    background: #D9D9D9;
    /* background: rgba(255, 193, 14, 0.5); */
}

.label-sm {
    margin-left: -35px !important;
    width: 225px;
}

/* .radio-style {
    padding: 10px 0 10px 10px !important;
    width: 100%;
} */

#order-now {
    font-family: "avenir-next-lt-pro-regular";
}


#OrderNowBtn {
    margin-left: 30rem;
    width: 34%;
    /* float: right; */
    background-color: #C4161C;
    color: #fff !important;
}