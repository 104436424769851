@font-face {
    font-family: "avenir-next-lt-pro-regular";
    src: url("../../fonts/AvenirNextLTPro-Regular.otf") format("woff"),
        url("../../fonts/AvenirNextLTPro-Regular.otf") format("opentype"),
        url("../../fonts/AvenirNextLTPro-Regular.otf") format("truetype");
}

#menu {
    font-family: "avenir-next-lt-pro-regular";
    text-transform: none;
    width: 100%;
    height: auto;
}

.navbar-nav .nav-custom:hover {
    background-color: #FFC10E;
    color: #000 !important;
}

.nav-custom {
    color: #fff;
    margin-left: 30px;
}

.navbar-nav .nav-custom:hover {
    background-color: #FFC10E;
    color: #000 !important;
    padding-left: auto;
}

button.navbar-toggler {
    margin-left: 24rem;
    margin-top: -2rem;
    background-color: #FFC10E;
}

.modal-content {
    background-color: #BFBFBF !important;
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
    margin-left: 23rem;
    margin-top: 4rem;
}



#modalSearch {
    padding: 0.2rem 3rem 0.2rem 1rem;
    border-radius: 1rem;

}
.active {
    border-bottom: 2.5px solid #FFC10E;
    padding-left: 0px !important;
}

.logo-style {
    height: 65px;
}




@media screen and (max-width: 500px) {
    .logo-style {
        margin-top: 8px;
    }
    button.navbar-toggler.collapsed {
        margin-left: 16rem;
    }

    button.navbar-toggler {
        margin-left: 16rem;
    }
    .modal-content {
        background-color: #BFBFBF !important;
        border-bottom-left-radius: 1rem !important;
        border-bottom-right-radius: 1rem !important;
        margin-left: 0rem;
        margin-top: 4rem;
    }
}

@media(min-width: 500px) and (max-width: 768px) {
    button.navbar-toggler.collapsed {
        margin-left: 25rem;
    }

    button.navbar-toggler {
        margin-left: 25rem;
    }
    .modal-content {
        background-color: #BFBFBF !important;
        border-bottom-left-radius: 1rem !important;
        border-bottom-right-radius: 1rem !important;
        margin-left: 0rem;
        margin-top: 4rem;
    }
}

@media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (-webkit-min-device-pixel-ratio: 1) {
        button.navbar-toggler.collapsed {
            margin-left: 40rem;
        }

        button.navbar-toggler {
            margin-left: 40rem;
        }
        .modal-content {
            background-color: #BFBFBF !important;
            border-bottom-left-radius: 1rem !important;
            border-bottom-right-radius: 1rem !important;
            margin-left: 0rem;
            margin-top: 4rem;
        }
    }